<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1" v-if="navigete.length==1 && navigete!=null && navigete!='' && navigete!='null'">
        ตั้งค่า <v-icon>mdi-chevron-right</v-icon>
        {{ navigete[0]['title'] }}
      </v-card-title>
    </v-card>

    <v-row row wrap class="ma-0">
      <v-col cols="12" md="3" class="pa-0">
        <v-card elevation="1" class="v-card-margin-bottom-30">
          <v-card-title>
            ค่าแพ็คสินค้าเริ่มต้น (Best Express)
            <v-spacer/>
          </v-card-title>
          <v-card-text>
            <v-text-field
              :value="PackCostFormat.BEST.PackCost"
              @change="value => PackCostFormat.BEST.PackCost = value"
              autocomplete="off"
              label="ราคาแพ็ค"
              ref="refPackCostFormatBESTPackCost"
              :rules="[ v => !!v || 'กรุณาระบุ', ]"
              outlined
              hide-details
              @keypress="isNumberWithDot($event)"
              class="pa-1"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card elevation="1">
      <v-card-actions>
        <v-spacer/>
        <v-btn color="success" outlined class="px-10 ma-2" @click="SubmitPage()">
          <v-icon class="fn-16">mdi-content-save</v-icon>
          <span class="fn-14"><b> บันทึก </b></span>
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { manageShopService } from '@/website/global'
  import { AlertSuccess, AlertError } from '@/website/global_alert'
  import {isNumberWithDot} from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,
      navigete: [],

      PackCostFormat: []
    }),
    computed: {
    },
    watch: {
    },
    async created() {
      document.title = this.$router.history.current.meta.title
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      await this.StartPage()
      this.page_loading = false
    },
    methods: {
      async StartPage(){
        let response = await axios.post(manageShopService+'manageShop/getPackCost', {
          'BranchID': localStorage.getItem('Branch_BranchID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status=='Success'){
          // แสดงราคาคงที่
          this.PackCostFormat = response.data.PackCostFormat
          this.PackCostFormat.BEST.PackCost = parseFloat(this.PackCostFormat.BEST.PackCost).toFixed(2)
          this.PackCostFormat.KERRY.PackCost = parseFloat(this.PackCostFormat.KERRY.PackCost).toFixed(2)
          this.PackCostFormat.ThaipostEMS.PackCost = parseFloat(this.PackCostFormat.ThaipostEMS.PackCost).toFixed(2)
          this.PackCostFormat.ThaipostRegister.PackCost = parseFloat(this.PackCostFormat.ThaipostRegister.PackCost).toFixed(2)
          this.PackCostFormat.ThaipostNormal.PackCost = parseFloat(this.PackCostFormat.ThaipostNormal.PackCost).toFixed(2)
          this.PackCostFormat.NIM.PackCost = parseFloat(this.PackCostFormat.NIM.PackCost).toFixed(2)
          this.PackCostFormat.FLASH.PackCost = parseFloat(this.PackCostFormat.FLASH.PackCost).toFixed(2)
        }
      },
      async SubmitPage(){
        this.loading = true
        let response = await axios.post(manageShopService+'manageShop/UpdatePackCost', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'PackCostFormat': this.PackCostFormat
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status=='Success'){
          await this.StartPage()
          this.loading = false
          this.AlertSuccess()
        } else {
          this.loading = false
          this.AlertError()
        }
      },
      isNumberWithDot,
      AlertSuccess,
      AlertError,
    },
  }
</script>
